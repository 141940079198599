import axios from '../../axios-auth';
import router from '../../router';
import Vue from 'vue';

const state = {
  selectedDay: null,
  calendarRewards: [],
};

const mutations = {
  setSelectedDay(state, selectedDay) {
    state.selectedDay = selectedDay;
  },
  setCalendarRewards(state, noOfDays) {
    if(!isNaN(noOfDays)){
      state.calendarRewards = new Array(noOfDays);
    }else{
      state.calendarRewards = [...noOfDays]
    }
  },
  populateCalendarCell(state, payload) {
    Vue.set(state.calendarRewards, payload.cell, payload.data);
    // state.calendar[payload.cell] = payload.data;
    payload.vm.$bvModal.hide('add-daily-reward-modal');
  },
};

const actions = {
  saveCalendar({ commit, rootGetters }, payload) {
    axios
      .post('api/savecalendar', payload.data.calendar, {
        headers: {
          Authorization: 'Bearer ' + rootGetters.token,
        },
      })
      .then(res => {
        const msg = 'You successfully saved a new calendar';
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: 'success',
        };
        payload.vm.resetFields();
        payload.vm.loading = false;
        commit('makeToast', toast);
      })
      .catch(rej => {
        const msg = 'There was an error saving your calendar';
        payload.vm.loading = false;
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: 'danger',
        };

        commit('makeToast', toast);
      });
  },
};

const getters = {
  selectedDay(state) {
    return state.selectedDay;
  },
  calendarRewards(state) {
    return state.calendarRewards;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
