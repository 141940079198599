import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/adddailyreward',
      component: () => import('@/views/theme/Base'),
      children: [
        {
          path: '/adddailyreward',
          name: 'adddailyreward',
          component: () => import('@/views/pages/AddDailyRewards.vue'),
        },
        {
          path: '/addspecialevent',
          name: 'addspecialevent',
          component: () => import('@/views/pages/AddSpecialEvents.vue'),
        },
        {
          path: '/addrewardpoolitem',
          name: 'addrewardpoolitem',
          component: () => import('@/views/pages/AddRewardPoolItem.vue'),
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/pages/auth/Auth'),
      children: [
        {
          name: 'login',
          path: '/login',
          component: () => import('@/views/pages/auth/Login'),
        },
        {
          name: 'register',
          path: '/register',
          component: () => import('@/views/pages/auth/Register'),
        },
      ],
    },
    {
      path: '*',
      redirect: '/404',
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/views/pages/error/Error-1.vue'),
    },
  ],
  mode: 'history',
});
