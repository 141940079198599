import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth.module';
import htmlClass from './htmlclass.module';
import config from './config.module';
import breadcrumbs from './breadcrumbs.module';
import rewardpool from './modules/rewardpool';
import dailyreward from './modules/dailyreward';
import specialevents from './modules/specialevents';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    rewardpool,
    dailyreward,
    specialevents,
  },
});
