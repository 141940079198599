import ApiService from '@/common/api.service';
import axios from '../axios-auth';
import JwtService from '@/common/jwt.service';
import router from '../router';

// action types
export const VERIFY_AUTH = 'verifyAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER = 'register';
export const UPDATE_USER = 'updateUser';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const SET_ERROR = 'setError';

const state = {
  errors: null,
  user: {},
  isAuthenticated: localStorage.getItem('token') !== null ? true : false,
  token: localStorage.getItem('token') || null,
  username: localStorage.getItem('username') || null,
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  token(state) {
    return state.token;
  },
  username(state) {
    return state.username;
  },
};

const actions = {
  [LOGIN]({ commit }, credentials) {
    let params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', credentials.username);
    params.append('password', credentials.password);
    axios({
      method: 'post',
      url: 'oauth/token',
      auth: { username: 'first-client', password: 'noonewilleverguess' },
      headers: { 'Content-type': 'application/x-www-form-urlencoded; charset=utf-8' },
      data: params,
    })
      .then(res => {
        commit('SET_AUTH', res.data);
        const now = new Date();
        const expirationDate = new Date(now.getTime() + res.data.expires_in * 1000);
        localStorage.setItem('expirationDate', expirationDate);
        axios
          .get('/api/loggedinUser', { headers: { Authorization: 'Bearer ' + res.data.access_token } })
          .then(result => {
            commit('setAccessToken', {
              username: result.data.userAuthentication.principal.username,
              role: result.data.authorities[0].authority,
              token: res.data.access_token,
            });
          });
        router.push({ name: 'adddailyreward' });
      })
      .catch(err => {
        commit(SET_ERROR, 'Bad Credentials');
      });
  },
  logout({ commit, rootGetters }) {
    axios
      .get('/api/logouts?access_token=' + rootGetters.token)
      .then(res => {
        commit('destoryAccessToken');
        router.replace('/login');
      })
      .catch(err => {});
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('users', { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (context.state.isAuthenticated) {
      return true;
    } else {
      return false;
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put('user', user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  },
  tryAutoLogin({ commit }) {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    const expirationDate = new Date(localStorage.getItem('expirationDate'));
    const now = new Date();

    if (now >= expirationDate) {
      commit('destoryAccessToken');
      router.replace('/login');
      return;
    }

    const username = localStorage.getItem('username');
    const role = localStorage.getItem('role');
    commit('setAccessToken', {
      username,
      token,
      role,
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  makeToast(state, payload) {
    payload.vm.$bvToast.toast(payload.msg, {
      title: 'Message',
      autoHideDelay: 2000,
      appendToast: false,
      variant: payload.color,
    });
  },
  destoryAccessToken(state) {
    localStorage.clear();
  },
  SET_AUTH(state, user) {
    state.isAuthenticated = true;
    state.errors = {};
    state.token = user.access_token;
    localStorage.setItem('token', user.access_token);
  },
  setAccessToken(state, auth) {
    state.token = auth.token;
    state.username = auth.username;
    state.role = auth.role;
    localStorage.setItem('username', auth.username);
    localStorage.setItem('role', auth.role);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
