import axios from '../../axios-auth';
import router from '../../router';
import Vue from 'vue';

const state = {
  selectedDate: null,
  eventRewards: [],
};

const mutations = {
  setSelectedDate(state, selectedDate) {
    state.selectedDate = selectedDate;
  },
  populateEventCell(state, payload) {
    Vue.set(state.eventRewards, payload.cell, payload.data);
    payload.vm.$bvModal.hide('add-daily-reward-modal');
  },
  setEventRewards(state, value){
    state.eventRewards = value;
  }
};

const actions = {
  // saveEvent({ commit, rootGetters }, payload) {
  //   let formData = new FormData();
  //   formData.append('event', JSON.stringify(payload.data.event));
  //   // formData.append('file', payload.data.file);
  //   axios
  //     .post('api/savespecialevent', formData, {
  //       headers: {
  //         Authorization: 'Bearer ' + rootGetters.token,
  //         // 'Content-Type': 'multipart/form-data',
  //       },
  //     })
  //     .then(res => {
  //       const msg = 'You successfully saved a new event';
  //       const toast = {
  //         vm: payload.vm,
  //         msg: msg,
  //         color: 'success',
  //       };
  //       payload.vm.resetFields();
  //       commit('makeToast', toast);
  //     })
  //     .catch(rej => {
  //       const msg = 'There was an error saving your event';
  //       const toast = {
  //         vm: payload.vm,
  //         msg: msg,
  //         color: 'danger',
  //       };

  //       commit('makeToast', toast);
  //     });
  // },
  saveEvent({ commit, rootGetters }, payload) {
    axios
      .post('api/savespecialevent', payload.data.event, {
        headers: {
          Authorization: 'Bearer ' + rootGetters.token,
        },
      })
      .then(res => {
        const msg = 'You successfully saved a new event';
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: 'success',
        };
        payload.vm.resetFields();
        payload.vm.loading = false;
        commit('makeToast', toast);
      })
      .catch(rej => {
        const msg = 'There was an error saving your event';
        payload.vm.loading = false;
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: 'danger',
        };

        commit('makeToast', toast);
      });
  },
};

const getters = {
  selectedDate(state) {
    return state.selectedDate;
  },
  eventRewards(state) {
    return state.eventRewards;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
