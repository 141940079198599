import axios from '../../axios-auth';
import router from '../../router';

const state = {
  rewardPoolCategories: [],
  rewardPoolItem: null,
};

const mutations = {
  setRewardPoolCategories(state, rewardPoolCategories) {
    state.rewardPoolCategories = rewardPoolCategories;
  },
  setRewardPoolItem(state, rewardPoolItem) {
    state.rewardPoolItem = rewardPoolItem;
  },
};

const actions = {
  saveRewardPoolItem({ commit, rootGetters }, payload) {
    axios
      .post('api/rewardpoolitems', payload.data, {
        headers: {
          Authorization: 'Bearer ' + rootGetters.token,
        },
      })
      .then(res => {
        const msg = 'You successfully saved a new reward pool item';
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: 'success',
        };
        payload.vm.resetFields();
        commit('makeToast', toast);
      })
      .catch(rej => {
        const msg = 'There was an error saving your reward pool item';
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: 'danger',
        };

        commit('makeToast', toast);
      });
  },
  getRewardPoolCategories({ commit, rootGetters }) {
    axios
      .get('api/rewardpoolcategories', {
        headers: {
          Authorization: 'Bearer ' + rootGetters.token,
        },
      })
      .then(res => {
        commit('setRewardPoolCategories', res.data);
      })
      .catch(err => {});
  },
  getRandomRewardPoolItemByCat({ commit, rootGetters }, payload) {
    axios
      .get('api/rewardpoolitems/' + payload.categoryId, {
        headers: {
          Authorization: 'Bearer ' + rootGetters.token,
        },
      })
      .then(res => {
        commit('setRewardPoolItem', res.data);
      })
      .catch(err => {});
  },
};

const getters = {
  rewardPoolCategories(state) {
    return state.rewardPoolCategories;
  },
  rewardPoolItem(state) {
    return state.rewardPoolItem;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
